import React, { useState } from 'react'
import './css/sendNotif.css'
import axios from 'axios'

function SendNotif() {
    const [text,settext] = useState()
    const [user,setuser] = useState()
    const [link,setlink] = useState()
    const [textLink,settextLink] = useState()

    const send  = async(e)=>{
        e.preventDefault()
        await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/Notif`,{
            text : text,
            user : user,
            link : link,
            textLink : textLink
        }).then((res)=>{
            console.log(res.data);
        })
    }

  return (
    <div className='sendNotif'>
      <form action="">
        <h6>العنوان</h6>
        <input value={textLink} onChange={(e)=>settextLink(e.target.value)} type="text"  placeholder='العنوان'/>
        <h6>id - user </h6>
        <input value={user} onChange={(e)=>setuser(e.target.value)} type="text"  placeholder='User Id' />
        <h6>النص</h6>
        <textarea value={text} onChange={(e)=>settext(e.target.value) } name="" id=""></textarea>
        <h6>الرابط</h6>
        <input value={link} onChange={(e)=>setlink(e.target.value) } type="text"  placeholder='Link'/>
        <hr />
        <button onClick={send} className='btn btn-primary'>Send</button>
      </form>
    </div>
  )
}

export default SendNotif
