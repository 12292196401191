import React, { useState } from 'react'
import './css/Dash.css'
import Users from './Users'
import Posts from './Posts'
import SendNotif from './SendNotif'
function Dashbord() {

  const [users,setUsersLength] = useState([])
  const [PostsLenght,setPostsLength] = useState([])

  const [getUser,setGetusers] = useState(false)
  const [getpsosts,setgetpsosts] = useState(false)
  const [setNotif,setSendNotif] = useState(true)

  const getPosts = ()=>{
    setGetusers(false)
    setgetpsosts(true)
    setSendNotif(false)
  }
  const getUsers = ()=>{
    setGetusers(true)
    setgetpsosts(false)
    setSendNotif(false)
  }
  const sendNotif = ()=>{
    setGetusers(false)
    setgetpsosts(false)
    setSendNotif(true)
  }
  return (
    <div className='col-12'>
      <div className="Dashbord-header">
        <h5 onClick={getPosts} >Posts : <span>{PostsLenght?.length} </span></h5>
        <h5 onClick={getUsers} >Users : <span> {users?.length} </span></h5>
        <h5 onClick={sendNotif} >send Notification</h5>
      </div>
      {getpsosts && <Posts setPostsLength={setPostsLength}/>}
      {getUser && <Users setUsersLength={setUsersLength} />}
      {setNotif &&  <SendNotif/>  }
      
    </div>
  )
}

export default Dashbord
