import React from 'react'
import './css/Loading.css'
function Loading() {
  return (
    <div className='loading'>
      <img src="./Blue and Orange Parrot Company Logo (4).jpg" alt="" />
    </div>
  )
}

export default Loading
