import React, { useEffect } from 'react'
import './css/emailVerify.css'
import { MdVerifiedUser } from "react-icons/md";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios'
import { toast } from 'react-toastify';
function EmailVerify() {

  const {token} = useParams()
  const {userId} = useParams()
    // /:userId/verify/:token
 useEffect(()=>{
  const verifyEmail = async()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/${userId}/verify/${token}`)
    .then((res)=>{
      if(res.data.message){
        toast.success(res.data.message)
        return;
      }
       console.log(res.data);
    })
  }
  verifyEmail()
 },[])
    
  return (
    <div className='emailVerify'>
  <div className="emailVerify-container">
    <h1>تم التحقق من البريد الإلكتروني <span className='text-success'><MdVerifiedUser/></span></h1>
    <p>لقد تم التحقق من بريدك الإلكتروني بنجاح.</p>
    <Link to={'/login'}>
    <button className="emailVerify-button btn btn-success"> تسجيل الدخول  </button>
    </Link>
  </div>
</div>
  )
}

export default EmailVerify
