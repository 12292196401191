import React from 'react';
import './css/about.css'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";



function About() {
    const navigate = useNavigate()
  return (
    <div className="container mt-5">
  <div className="CreatePost-header">
    <Link to={-1}>
      <h2><IoIosArrowForward /></h2>
    </Link>
    <h3>نبذة عن الموقع</h3>
    <img onClick={() => navigate('/')} id='imglogo' src="http://res.cloudinary.com/dvivzto6g/image/upload/v1726327800/ikzcmqayqhrjgpxluw6v.png" alt="Logo elfanane" />
  </div>

  <div className="row">
    <div className="col-lg-8 mx-auto text-center">
      <h1 className="display-4 mb-4">مرحباً بك في منصة <strong>ELFANANE.COM</strong></h1>
      <p className="lead">
        <strong>elfanane.com</strong> هي أفضل منصة لعرض وبيع الأعمال الفنية في المغرب والعالم العربي. 
        نحن نمكّن الفنانين من تسويق أعمالهم <strong>اللوحات الفنية</strong> و<strong>الجداريات</strong> عبر الإنترنت، 
        مما يسهل على عشاق الفن اكتشاف وشراء أعمال فريدة من نوعها. 
      </p>
    </div>
  </div>

  <div className="row mt-5">
    <div className="col-lg-8 mx-auto">
      <h2 className="text-center mb-4">لماذا تختار <strong>ELFANANE.COM</strong>؟</h2>
      <p>
        <strong>ELFANANE.COM</strong> تقدم للفنانين المغاربة والعالميين فرصة فريدة لترويج أعمالهم وبيعها بسهولة. 
        استمتع بالوصول إلى جمهور عالمي وزد من فرص النجاح الفني من خلال أدواتنا المتطورة وخدماتنا الاحترافية.
      </p>
    </div>
  </div>

  <div className="row mt-5">
    <div className="col-lg-10 mx-auto">
      <h2 className="text-center mb-4">ميزات منصة <strong>elfanane.com</strong></h2>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <strong>عرض وبيع اللوحات:</strong> نشر أعمالك الفنية وجعلها متاحة للبيع عبر <strong>elfanane.com</strong>.
        </li>
        <li className="list-group-item">
          <strong>دورات تعليمية:</strong> تحسين مهاراتك الفنية من خلال <strong>دورات متخصصة</strong> وورش العمل.
        </li>
        <li className="list-group-item">
          <strong>إعلانات مخصصة:</strong> استفد من إعلانات تستهدف جمهورك مباشرة لزيادة المبيعات.
        </li>
        <li className="list-group-item">
          <strong>تواصل مباشر:</strong> تواصل مع المشترين والفنانين الآخرين بسهولة وبدون وسطاء.
        </li>
        <li className="list-group-item">
          <strong>دعم فني:</strong> فريق الدعم لدينا متاح للإجابة عن استفساراتك وحل أي مشاكل تواجهك.
        </li>
        <li className="list-group-item">
          <strong>إدارة الحسابات الشخصية:</strong> تحديث ملفك الشخصي بسهولة لتعكس أحدث أعمالك.
        </li>
      </ul>
    </div>
  </div>

  <div className="row mt-5">
    <div className="col-lg-8 mx-auto">
      <h2 className="text-center mb-4">كيف تعمل منصة <strong>ELFANANE.COM</strong>؟</h2>
      <p>
        بعد التسجيل في <strong>elfanane.com</strong>، يمكنك تحميل صور لأعمالك الفنية مع تحديد الأسعار والوصف المناسب. 
        العملاء يمكنهم التواصل معك لإتمام عملية الشراء بسهولة.
      </p>
    </div>
  </div>

  <div className="row mt-5 mb-5">
    <div className="col-lg-8 mx-auto text-center">
      <h2 className="mb-4">انضم إلى مجتمع <strong>elfanane.com</strong> الآن!</h2>
      <p>
        سواء كنت فنانًا مبتدئًا أو محترفًا، فإن <strong>ELFANANE.COM</strong> هي منصتك المثالية للتواصل مع جمهور واسع، 
        بيع أعمالك، وتعلم تقنيات جديدة. استمتع بتجربة فريدة واستثمر في موهبتك.
      </p>
    </div>
  </div>
</div>

  );
}

export default About;
