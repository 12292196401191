import React, { useEffect, useState } from 'react'
import './css/Dash.css'
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Posts({setPostsLength}) {

    const [posts,setPosts] = useState([])
    const navigate = useNavigate()

    useEffect(()=>{
        const getAllPosts = async()=>{
            await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/posts`)
            .then((res)=>{
                setPosts(res.data)
                setPostsLength(res.data)
            })
        }
        getAllPosts()
    },[])
    const deletePost = async(PostId)=>{
        await  axios.delete(`${process.env.REACT_APP_API_URL}/api/auth/post/`+PostId)
        .then((res)=>{
            toast.success('تم الحدف بنجاة ')
        })
    }

  return (
    <div className="Posts-Dash">
        {posts?.map(post=>{
          return(
        <div  key={post?._id} className="Post-img-Dash">
        <img onClick={()=> navigate('/post-details/'+post?._id)} src={post?.image?.url} alt="" />
        <h2 onClick={()=>deletePost(post?._id)}><MdDelete/> </h2>
        </div>
            )
        })}
    </div>
  )
}

export default Posts
