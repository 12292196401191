import React, { useEffect, useState } from 'react'
import './css/Users.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
function Users({setUsersLength}) {

    const navigate = useNavigate()
    const [users,setUsers] = useState([])

    useEffect(()=>{
        const getUser = async()=>{
            await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/users`).then((res)=>{
                setUsers(res.data)
                setUsersLength(res.data)
                console.log(res.data);
            })
        }
        getUser()
    },[])

    const Profile = (username)=>{
        navigate('/'+username)
    }
    
    const verify = async(userId)=>[
        await axios.put(`${process.env.REACT_APP_API_URL}/api/auth/user/verfyaccount/`+userId)
        .then((res)=>{
            toast.success('تم توتيق الحساب')
        })
    ]
    const deleteUser = async(userId)=>[
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/auth/user/`+userId)
        .then((res)=>{
            toast.success('تم حدف الحساب بنجاح')
        })
    ]
    return (
    <div className='Dash-users'>
        <div className="Dash-users-component">
            <div className="Dash-users-component-header">
                <h6 className='col' id='h6-header'>img</h6>
                <h6 className='col' id='h6-header'>name</h6>
                <h6 className='col' id='h6-header'>email</h6>
                <h6 className='col' id='h6-header'>id</h6>
                <h6 className='col' id='h6-header'>posts</h6>
                <h6 className='col' id='h6-header'>notification</h6>
                <h6 className='col' id='h6-header'>Action</h6>
            </div>
            {users?.map(user=>{
                return (
                    <div  key={user?._id} className="Dash-user ">
                    <div className={user?.isAccountVerified ? "Dash-user-img bg-primary col" :"Dash-user-img  col"}>
                    <img  src={user?.profilePhoto?.url} alt="" />
                    </div>
                    <h6  id='h6-header' className={user?.isEmailVerified ? 'col bg-info text-white' : 'col bg-danger text-white'}>{user?.username} </h6>
                    <h6  id='h6-header' className='col'>{user?.email} </h6>
                    <h6  id='h6-header' className='col'>{user?._id} </h6>
                    <h6  id='h6-header' className='col'> {user?.posts?.length} </h6>
                    <h6  id='h6-header' className='col'> {user?.notification?.length} </h6>
                    <div className="Dash-user-views col ">
                        <button className='bg-success ' onClick={() => Profile(user?.username.replace(/ /g, "_"))} > views</button>
                        <button className='bg-danger '  onClick={()=>deleteUser(user?._id)} > delete</button>
                        <button className='bg-primary ' onClick={()=>verify(user?._id)}> verify </button>
                    </div>
            </div>
                )
            })}
           
        </div>
    </div>
  )
}

export default Users
