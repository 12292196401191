import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './redux/Store';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom'; // استيراد BrowserRouter

ReactGA.initialize('G-VJYYT3SDTV');
ReactGA.send('pageview');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter> {/* إضافة BrowserRouter هنا فقط */}
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
